import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="logo_transparent.png" className="App-logo" alt="DrftCo" />

      </header>
    </div>
  );
}

export default App;
